import type { AnalyticsCollector as KoalaSDK } from './analytics/collector'
import * as KoalaBrowser from './browser'

export { load } from './browser'

declare global {
  // eslint-disable-next-line no-var
  var koalaSettings:
    | {
        host?: string
        wssHost?: string
        // Allow users to set the anonymous profile id before loading the SDK
        profileId?: string
        sdk_settings?: {
          autocapture?: boolean
          segment_auto_track?: 'on' | 'off'
          form_collection?: 'on' | 'off'
          websocket_connection?: 'on' | 'off'
          authorized_referrers?: string[]
        }
      }
    | undefined

  interface Window {
    ko?: KoalaSDK
  }
}

/**
 * Get the project slug from data attributes or the script's url.
 */
function getProject(): string | undefined {
  let project = document.currentScript?.getAttribute('data-project')

  if (!project) {
    const [_file, id] = (document.currentScript?.getAttribute('src') || '').split('/').reverse()

    project = id
  }

  return project
}

function getProfileId(): string | undefined {
  return window.koalaSettings?.profileId
}

/**
 * Loads the Koala SDK
 */
async function loadSDK() {
  const project = getProject()

  if (!project) {
    console.error('Koala SDK: Missing required project attribute.')
    return
  }

  try {
    const profileId = getProfileId()
    window.ko = await KoalaBrowser.load({ project, profileId })
  } catch (error) {
    console.error('[KOALA]', 'Failed to load the Koala SDK.', error)
  }
}

/**
 * Automatically load the SDK when the script is parsed and executed
 */
loadSDK()
